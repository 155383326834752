import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Checkbox, Dimmer, Loader, Message, Pagination, Segment, Table, TableCell } from "semantic-ui-react";
import InstitutionDto from "../../models/Institutions/institutionDto";
import { useStore } from "../../stores/store";

export interface Props {
  institutions: InstitutionDto[] | undefined;
  loadingInProgress: boolean;
  currentPage: number | string | undefined;
  pageSize: number;
  totalItemsCount: number;
  blockActions: boolean;
  onPageChanged: (index: number | string | undefined) => void;
  onItemSelected: (institution: InstitutionDto | null) => void;
}

export default function InstitutionsList({
  institutions,
  loadingInProgress,
  blockActions,
  onPageChanged,
  totalItemsCount,
  currentPage,
  pageSize,
  onItemSelected,
}: Props) {
  const { t } = useTranslation("common");
  const { lookupsStore, commonStore } = useStore();
  const [selectedInstitution, setSelectedInstitution] = useState<InstitutionDto | null>(null);

  const selectIntitution = (institution: InstitutionDto) => {
    if (selectedInstitution && selectedInstitution?.id === institution.id) {
      setSelectedInstitution(null);
      onItemSelected(null);
    } else {
      setSelectedInstitution(institution);
      onItemSelected(institution);
    }
  };

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const emptyList = (
    <Segment className="injaz-empty-list-msg">
      <Message className="empty-list-msg" header={t("Institutions.NoInstitutions")} />
    </Segment>
  );

  const gridPaging = (
    <Pagination
      disabled={blockActions}
      boundaryRange={0}
      defaultActivePage={currentPage}
      totalPages={Math.ceil(totalItemsCount / pageSize)}
      onPageChange={(e, d) => onPageChanged(d.activePage)}
    />
  );

  const list = (
    <Segment className="injaz-manage-segment">
      <Table>
        <Table.Header>
          <Table.Row>
            <TableCell style={{ width: "2%" }}></TableCell>
            <TableCell style={{ width: "4%" }}>#</TableCell>
            <TableCell>{t("Institutions.Name")}</TableCell>
            <TableCell>{t("Institutions.InstitutionType")}</TableCell>
            <TableCell>{t("Institutions.Governate")}</TableCell>
            <TableCell>{t("Institutions.Directorate")}</TableCell>
            <TableCell>{t("Institutions.Gender")}</TableCell>
            <TableCell>{t("Institutions.DoubleShift")}</TableCell>
            <TableCell>{t("Institutions.HasSyrianStudentsPeriod")}</TableCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {institutions &&
            institutions.map((institution) => (
              <Table.Row key={institution.id}>
                <TableCell>
                  <Checkbox
                    checked={selectedInstitution != null && selectedInstitution.id === institution.id}
                    onChange={() => {
                      selectIntitution(institution);
                    }}
                  />
                </TableCell>
                <TableCell>{institution.id}</TableCell>
                <TableCell>
                  <NavLink to={`/institutions/${institution.id}/details`}>{institution.name}</NavLink>
                </TableCell>
                <TableCell>{institution.institutionType == null?"": lookupsStore.getInstitutionsTypesValue(institution.institutionType, commonStore.lang)}</TableCell>
                <TableCell>{institution.governateName}</TableCell>
                <TableCell>{institution.directorateName}</TableCell>
                <TableCell>{institution.genderType && lookupsStore.getGenderPreferenceValue(institution.genderType, commonStore.lang)}</TableCell>
                <TableCell>{institution.schoolWorksWithDoubleShift ? t("Common.Yes") : t("Common.Yes")}</TableCell>
                <TableCell>{institution.hasSyrianStudentsPeriod ? t("Common.Yes") : t("Common.Yes")}</TableCell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {totalItemsCount > 10 && gridPaging}
    </Segment>
  );

  return (
    <>
      {loadingInProgress && loader}
      {!loadingInProgress && institutions != null && institutions.length > 0 && list}
      {!loadingInProgress && (institutions == null || institutions.length === 0) && emptyList}
    </>
  );
}
